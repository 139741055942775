import { History } from "history";
import { inject, injectable } from "inversify";
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  when,
  toJS,
} from "mobx";
import * as moment from "moment";
import { isNotUndefinedPredicate, notEmpty } from "../../../../helpers";
import {
  ApiServiceSymbol,
  CoreApiServiceSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  SettingsStoreSymbol,
  UserStoreSymbol,
} from "../../../../inversify/symbols";
import {
  AcademyServiceTypes,
  IAcademyServiceModel,
  MotorcycleLessonType,
  ProductType,
  VehicleType,
} from "../../../../models/AcademyServiceModel/interfaces";
import { ErrorMessage } from "../../../../models/ErrorMessage";
import {
  GearType,
  IInstructorModel,
  LanguagesSpoken,
  MultipleGearType,
} from "../../../../models/InstructorModel/interfaces";
import { ILocationModel } from "../../../../models/LocationModel/interfaces";
import { PendingStatus } from "../../../../models/PendingStatus";
import { IUserModel } from "../../../../models/UserModel/interfaces";
import {
  IApiSaldoAttendee,
  IApiService,
} from "../../../../services/ApiService/interfaces";
import {
  isAxiosError,
  isCoreApiError,
} from "../../../../services/AuthService/interfaces";
import {
  IBookingConfig,
  ICoreApiService,
} from "../../../../services/CoreApiService/interfaces";
import { II18nService } from "../../../../services/I18nService/interfaces";
import {
  ISettingsStore,
  isIntroServiceKey,
  ServiceKey,
} from "../../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../../stores/UserStore/interfaces";
import { IClassMeta, IDatePickerOption } from "../../../shared/DatePicker";
import {
  ICityOption,
  IInstructorOption,
  IInstructorSelectOption,
  IRegionOption,
  IServiceOption,
} from "../../../shared/forms/booking/interfaces";
import { calcServiceOptionType } from "../../../shared/forms/booking/ServiceListOption";
import { ITimePickerOpt } from "../../../shared/forms/TimePickerExtended";
import {
  ANY_EN_INSTRUCTORS_OPT,
  ANY_SV_INSTRUCTORS_OPT,
  dict,
} from "../../../widget/DemoScreen/DemoForm/DemoFormModel";
import { Attendee } from "../../OrderScreen/OrderForm/Attendee";
import { AttendeesList } from "../../OrderScreen/OrderForm/AttendeesList";
import {
  ERROR_CODE,
  IAttendee,
  IFormStateAttendee,
  IIntervalWithMeta,
  IIntervalWithWorkload,
} from "../../OrderScreen/OrderForm/interfaces";
import { IScheduleModel } from "./interfaces";
import { FormFieldState } from "@/models/FormFieldState";
import { CalendarEventType } from "../../ScheduleScreen/Calendar/interfaces";

@injectable()
export class ScheduleModel implements IScheduleModel {
  @computed
  public get regionOptions(): IRegionOption[] {
    const serviceRegions = this.services
      .filter((ser) => ser.service.vehicleType === this.selectedWheeledVehicle)
      .map((ser) => ser.service.regions)
      .flat();
    const serviceRegionsByVechicle = [...new Set(serviceRegions)];

    let options = this.settingsStore.regions
      .slice()
      .filter((r) => r.isVisible)
      .filter((r) => serviceRegionsByVechicle.includes(r.id))
      .map((region) => {
        return {
          label: region.name[this.i18n.currentLanguage],
          model: region,
          value: region.id,
        };
      });
    options = options.filter((option) => {
      return this.services
        .map((serviceData) => serviceData.service.regions)
        .flat()
        .some((regionId) => regionId === option.value);
    });

    return options.sort((a, b) => {
      try {
        return a.label.localeCompare(b.label, this.i18n.currentLanguage, {
          numeric: true,
        });
      } catch (e) {
        return 0;
      }
    });
  }
  @computed
  public get allCityOptions(): ICityOption[] {
    const { user } = this.userStore;

    let options: Array<{
      model: ILocationModel;
      label: string;
      value: number;
    }> = this.settingsStore.locations
      .slice()
      .filter((loc) =>
        [
          ...(this.selectedRegion?.model.locationsIds || []),
          ...(this.selectedService?.model.ownLocations || []),
          ...(this.selectedService?.model.locations || []),
        ].includes(loc.id)
      )
      .sort((a, b) => {
        return a.listOrder - b.listOrder;
      })
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });

    if (user) {
      const { profile } = user;

      if (profile) {
        options = options.filter((option) => {
          return this.services
            .map((serviceData) => serviceData.service.locations)
            .flat()
            .some((regionId) => regionId === option.value);
        });
      }
    }
    return options;
  }
  @computed get serviceWithExchange(): IServiceOption | undefined {
    const selectedService = this.selectedService?.model;
    if (selectedService?.isExchangeable) {
      console.log(selectedService?.isExchangeable);
      if (this.selectedCity?.value) {
        const [exchangedCityId, servicesInLocation] =
          Object.entries(selectedService.exchangeables || {}).find(
            ([exchangeCityId, exchangeServiceLocations]) => {
              return (
                this.selectedCity?.value === +exchangeCityId &&
                (exchangeServiceLocations?.length || 0) > 0
              );
            }
          ) || [];
        if (exchangedCityId) {
          return this.completeServiceOptions
            .filter((s) => s.model.productType === ProductType.Service)
            .find((s) => s.value === +servicesInLocation![0]);
        }
      }
    }
    return this.selectedService;
  }

  @computed
  get realLocation() {
    if (this.selectedService?.model?.meta?.type === AcademyServiceTypes.Class) {
      return this.allCityOptions.filter(
        (option) => option.value === this.selectedService?.model.ownLocations[0]
      )[0];
    }
    return this.selectedCity;
  }

  /**
   * City options filtered by service
   */
  @computed
  public get cityOptions(): ICityOption[] {
    const { user } = this.userStore;
    const serviceKey = this.selectedService?.model.meta?.key;
    const serviceLocations = this.services
      .filter((ser) => ser.service.vehicleType === this.selectedWheeledVehicle)
      .map((ser) => ser.service.locations)
      .flat();
    const serviceLocationsByVechicle = [...new Set(serviceLocations)];

    const exchangeableLocations = Object.entries(
      this.selectedService?.model?.exchangeables || {}
    ).reduce((acc, [k, v]) => [...acc, +k], [] as number[]);

    let options: Array<{
      model: ILocationModel;
      label: string;
      value: number;
    }> = this.settingsStore.locations
      .slice()
      .filter((loc) =>
        [
          ...(this.selectedRegion?.model.locationsIds || []),
          ...(this.selectedService?.model.ownLocations || []),
          ...(this.selectedService?.model.locations || []),
          ...exchangeableLocations,
        ].includes(loc.id)
      )
      .filter((loc) => {
        const locations =
          this.selectedService?.model.meta?.type === AcademyServiceTypes.Class
            ? /*serviceKey === ServiceKey.Risk2 ? [
              ...(this.selectedService?.model.ownLocations || [])
            ] || [] :*/ [
                ...(this.selectedService?.model.ownLocations || []),
                ...exchangeableLocations,
              ] || []
            : [
                ...(this.selectedService?.model.locations || []),
                ...exchangeableLocations,
              ];
        return locations.includes(loc.id);
      })
      .filter((loc) => serviceLocationsByVechicle.includes(loc.id))
      .sort((a, b) => {
        return a.listOrder - b.listOrder;
      })
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });

    if (user) {
      const { profile } = user;

      if (profile) {
        options = options.filter((option) => {
          return this.services
            .map((serviceData) => serviceData.service.locations)
            .flat()
            .some((regionId) => regionId === option.value);
        });
      }
    }
    return options;
  }
  @computed
  public get instructorOptions(): IInstructorOption[] {
    const { gearType, selectedCity, selectedService } = this;

    if (!selectedCity) {
      return [];
    }

    const { availableInstructorsIds } = selectedCity.model;
    const availableUnitsIds = selectedService?.model.availableUnitsIds;

    const instructorOptions = this.settingsStore.instructors
      .filter((instructor) => {
        return availableInstructorsIds
          ? availableInstructorsIds.find((id) => instructor.id === id)
          : true;
      })
      .filter((instructor) => instructor.isActive)
      .filter((instructor) => {
        if (this.selectedVehicleGearType.value) {
          return (
            this.selectedVehicleGearType.value &&
            instructor.instructorVehicleGearTypes?.includes(
              this.selectedVehicleGearType.value
            )
          );
        }
        if (
          this.selectedWheeledVehicle === VehicleType.mc &&
          this.isMCPreSelected
        ) {
          return (
            this.isMCPreSelected &&
            instructor.instructorVehicleGearTypes?.includes(
              this.isMCPreSelected
            )
          );
        }
        if (
          this.selectedWheeledVehicle === VehicleType.car &&
          this.isCarPreSelected
        ) {
          return (
            this.isCarPreSelected &&
            instructor.instructorVehicleGearTypes?.includes(
              this.isCarPreSelected
            )
          );
        } else return true;
      })
      .filter((instructor) => {
        return (
          !availableUnitsIds ||
          availableUnitsIds.some(
            (availableUnitId) => availableUnitId === instructor.id
          )
        );
      })

      .sort((a, b) => {
        const posA = a.positionInLocation.get(selectedCity.value);
        const posB = b.positionInLocation.get(selectedCity.value);

        if (typeof posA === "number" && typeof posB === "number") {
          return posA - posB;
        } else {
          return 0;
        }
      })
      .map((instructor) => this.instructorToOption(instructor));

    console.debug({ instructorOptions });
    return instructorOptions;
  }

  @computed
  public get additionalInstructorOptions(): IInstructorSelectOption[] {
    const anyEnglishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_EN_INSTRUCTORS_OPT,
            label: this.i18n.i18next
              .t("order.chooseAnyEngInstructor")
              .toString(),
          }
        : undefined;

    const anySwedishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_SV_INSTRUCTORS_OPT,
            label: this.i18n.i18next.t("order.chooseAnyInstructor").toString(),
          }
        : undefined;

    return [anySwedishInstrOpt, anyEnglishInstrOpt].filter(notEmpty);
  }

  @computed
  public get options(): IInstructorSelectOption[] {
    return !!this.instructorOptions.length
      ? [
          ...this.additionalInstructorOptions,
          ...this.instructorOptions.map((opt) => {
            const { model, ...rest } = opt;
            return {
              ...rest,
              picture: model.picture,
            };
          }),
        ]
      : [];
  }

  @computed
  public get englishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.EN)
      )
      .map((el) => el.model.id);
  }
  @computed
  public get swedishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.SV)
      )
      .map((el) => el.model.id);
  }

  @computed
  public get instructorIdsList(): number[] {
    if (this.selectedOption?.value === dict.svVal) {
      return this.swedishSpeakingInstructors;
    } else if (this.selectedOption?.value === dict.enVal) {
      return this.englishSpeakingInstructors;
    } else {
      return [];
    }
  }

  @computed
  public get lessonsLeft(): number {
    const { user } = this.userStore;
    if (!user) {
      return 0;
    }
    return user.saldoBalance;
  }
  @computed
  get fields() {
    if (isIntroServiceKey(this.selectedService?.model?.meta?.key)) {
      return [this.attendees];
    }
    return [] as any[];
  }
  @computed
  public get submitAvailable(): boolean {
    const isValidFields = !this.fields.some((field) => !field.isValid);
    return (
      !!isValidFields &&
      !!this.selectedDate &&
      !!this.selectedTime &&
      (!!this.selectedInstructor || !!this.selectedService?.isClass)
    );
  }

  @computed
  public get services(): Array<{ service: IAcademyServiceModel; qty: number }> {
    return this.settingsStore.services
      .slice()
      .map((service) => {
        const saldoItem = this.userStore.user?.saldo.find(
          (saldoService) =>
            saldoService.id === service.id &&
            saldoService.type === service.productType
        );
        return saldoItem
          ? {
              service,
              qty: saldoItem.qty,
            }
          : undefined;
      })
      .filter(isNotUndefinedPredicate);
  }

  @computed
  public get completeServiceOptions(): IServiceOption[] {
    const hasTestLesson =
      this.userStore.user &&
      this.userStore.user.profile &&
      !!this.userStore.user.profile.testLessonDate;

    const services = this.settingsStore.services
      .slice()
      .map((service) => {
        const saldoItem = this.userStore.user?.saldo.find(
          (saldoService) => saldoService.id === service.id
        );
        return {
          service,
          qty: saldoItem?.qty || 0,
        };
      })
      .filter(isNotUndefinedPredicate);

    return services
      .map((serviceData): IServiceOption => {
        const { service, qty } = serviceData;
        return {
          isHide: hasTestLesson && service.meta?.key === ServiceKey.Test,
          label: `${service.name[this.i18n.currentLanguage]} (${qty} left)`,
          model: service,
          type: calcServiceOptionType(service),
          value: service.id,
          isClass: service.isClass,
        };
      })
      .sort((a, b) => a.type - b.type);
  }

  @computed
  public get allServiceOptions(): IServiceOption[] {
    const hasTestLesson =
      this.userStore.user &&
      this.userStore.user.profile &&
      !!this.userStore.user.profile.testLessonDate;
    return this.services
      .filter((s) => {
        // only valid for selected region
        if (this.selectedRegion?.value) {
          return s.service.regions.includes(this.selectedRegion.value);
        }
        return true;
      })
      .filter((ser) => ser.service.vehicleType === this.selectedWheeledVehicle)
      .map((serviceData): IServiceOption => {
        const { service, qty } = serviceData;
        return {
          isHide: hasTestLesson && service.meta?.key === ServiceKey.Test,
          label: `${service.name[this.i18n.currentLanguage]} (${qty} left)`,
          model: service,
          type: calcServiceOptionType(service),
          value: service.id,
          isClass: service.isClass,
        };
      })
      .sort((a, b) => a.type - b.type);
  }

  /**
   * @deprecated
   */
  @computed
  public get serviceOptions(): IServiceOption[] {
    const selectedCity = this.selectedCity;
    if (!selectedCity) {
      return [];
    }

    const location = this.settingsStore.locations.find(
      (x) => x.id === selectedCity.value
    );
    if (!location) {
      console.error("Selected location not found in the SettingsStore");
      return [];
    }
    const hasTestLesson =
      this.userStore.user &&
      this.userStore.user.profile &&
      !!this.userStore.user.profile.testLessonDate;

    return this.services
      .filter((serviceData) => {
        return serviceData.service.locations.some((loc) => loc === location.id);
      })
      .sort((a, b) => {
        if (selectedCity.value) {
          const posA = a.service.positionInLocation.get(
            selectedCity.model.regionId
          );
          const posB = b.service.positionInLocation.get(
            selectedCity.model.regionId
          );

          if (typeof posA === "number" && typeof posB === "number") {
            return posA - posB;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
      .map((serviceData): IServiceOption => {
        const { service, qty } = serviceData;
        return {
          isHide: hasTestLesson && service.meta?.key === ServiceKey.Test,
          label: `${service.name[this.i18n.currentLanguage]} (${qty} left)`,
          model: service,
          type: calcServiceOptionType(service),
          value: service.id,
          isClass: service.isClass,
        };
      })
      .sort((a, b) => a.type - b.type);
  }
  @computed
  public get dateOptions(): ReadonlyArray<IDatePickerOption<IClassMeta>> {
    const datesSet = new Set<number>();
    const now = Date.now();

    console.group("Get date options");
    console.debug(
      "Available Instructors Slots",
      toJS(this.availableInstructorsSlots)
    );
    console.groupEnd();

    return this.availableInstructorsSlots
      .filter((slot) => {
        const { dates } = slot;
        const dateNow = Date.now();
        if (!slot.isClass) {
          return dates.from.getTime() - dateNow > 30 * 60 * 1000;
        }
        if (slot.isClass) {
          const d = new Date(dates.to);
          const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);
          return twoHourFromCourseEnd > dateNow;
        }
      })
      .map((slot) => {
        return {
          model: {
            disabled: !(slot.availableQty - this.bookableSlotNumber >= 0),
          },
          value: moment(slot.dates.from).clone().startOf("day").toDate(),
        };
      })
      .reduce((acc, element) => {
        const dateSlot = acc.find((e) => +e.value === +element.value);
        if (dateSlot) {
          dateSlot.model.disabled =
            dateSlot.model.disabled && element.model.disabled;
        }
        return [...acc, dateSlot ?? element];
      }, [] as any[])
      .filter((date) => {
        const time = date.value.getTime();
        const exists = datesSet.has(time);
        datesSet.add(time);
        return !exists;
      });
  }

  @computed
  public get timeOptions(): ITimePickerOpt[] {
    const { selectedDate } = this;
    const bookableSlotNumber = this.bookableSlotNumber;

    if (!selectedDate) {
      return [];
    }
    const now = Date.now();
    const dateTime = selectedDate.getTime();
    return (
      this.availableInstructorsSlots
        .filter((slot) => {
          const { dates, availableQty } = slot;
          if (!slot.isClass) {
            const d = new Date(dates.from);
            const halfHourFromNow = d.setTime(d.getTime() + -30 * 60000);
            return halfHourFromNow > now && availableQty >= bookableSlotNumber;
          }
          if (slot.isClass) {
            const d = new Date(dates.to);
            const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);
            return (
              twoHourFromCourseEnd > now && availableQty >= bookableSlotNumber
            );
          }
        })
        /*  return (
          slot.dates.from.getTime() > now &&
          slot.availableQty - this.bookableSlotNumber >= 0
        );
      })*/
        .filter((slot) => {
          return (
            moment(slot.dates.from)
              .clone()
              .startOf("day")
              .toDate()
              .getTime() === dateTime
          );
        })
        .sort((a, b) => a.dates.from.valueOf() - b.dates.from.valueOf())
        .map((slot) => {
          return {
            availableQty: slot.availableQty,
            date: slot.dates.from,
            entityId: slot.entityId,
            endTime: slot.dates.to,
            isClass: slot.isClass,
            seatsInSlot: slot.seatsInSlot,
          };
        })
    );
  }
  @computed
  get isVehicleType() {
    return this.selectedService?.model.vehicleType;
  }
  @computed
  public get transmissionMatter(): boolean {
    return (
      !!this.selectedService &&
      this.selectedService.model.meta?.type === AcademyServiceTypes.Lesson
    );
  }

  /**
   * @deprecated
   */
  @computed
  public get freeSlotsInSelectedIntro(): number {
    if (
      this.selectedService &&
      this.selectedService.model.meta &&
      this.userStore.user
    ) {
      if (isIntroServiceKey(this.selectedService.model.meta.key)) {
        const selectedServiceId = this.selectedService.value;
        return this.userStore.user.saldo.reduce((acc, val) => {
          const qty = val.id === selectedServiceId ? val.qty : 0;
          return acc + qty;
        }, 0);
      }
    }

    return 0;
  }

  @computed
  public get attendeesMode(): boolean {
    const selectedService = this.selectedService;
    if (!selectedService || !selectedService.model.meta) {
      return false;
    }
    return isIntroServiceKey(selectedService.model.meta.key);
  }

  @computed
  get bookableSlotNumber(): number {
    if (isIntroServiceKey(this.selectedService?.model?.meta?.key)) {
      return this.attendees.value.length;
    }
    return 1;
  }
  public selectedVehicleGearType = new FormFieldState<GearType | undefined>(
    undefined
  );

  @action
  public setVehicleGerType(isVehicleType: GearType) {
    this.selectedVehicleGearType.setValue(isVehicleType);
  }

  public get isCarPreSelected(): GearType | undefined {
    return this.selectedWheeledVehicle === VehicleType.car &&
      this.userStore.user?.settings.options.vehicleGearType
      ? this.userStore.user.settings.options.vehicleGearType
      : undefined;
  }
  public get isMCPreSelected(): GearType | undefined {
    return this.selectedWheeledVehicle === VehicleType.mc &&
      this.userStore.user?.settings.options.vehicleGearTypeMc
      ? this.userStore.user.settings.options.vehicleGearTypeMc
      : undefined;
  }

  public get currentVehicleGearType(): GearType | undefined {
    if (this.selectedVehicleGearType.value) {
      return this.selectedVehicleGearType.value;
    } else if (this.isMCPreSelected) return this.isMCPreSelected;
    else if (this.isCarPreSelected) return this.isCarPreSelected;
    else undefined;
  }

  public errorMsg = new ErrorMessage();
  @observable
  public gearType: GearType = GearType.Manual;

  @observable
  public slotsLeftInClass = 0;
  @observable
  public seatsLeftInLesson = 0;
  @observable
  public seatsInSlot = 0;
  @observable
  public lessonEndTime = new Date();

  public readonly updatingSlotsLeftInClassStatus = new PendingStatus();

  public attendees = new AttendeesList(observable.array(), {
    validator: flow<string | undefined, [IAttendee[]]>(function* (
      this: ScheduleModel,
      value: IAttendee[]
    ) {
      console.group("AttendeesList validator");
      try {
        value.forEach((attendee) => attendee.requestValidation());
        yield when(() => !value.some((attendee) => attendee.validationPending));
        let attendeeNotSaved = false;
        for (const attendee of value) {
          if (!attendee.saved) {
            attendee.addError("mustBeSaved");
            attendeeNotSaved = true;
          }
        }
        yield this.validateAttendeesCount();
        if (attendeeNotSaved) {
          return "hasNotSavedAttendees";
        }
        if (value.some((attendee) => !attendee.saved)) {
          return "someAttendeesAreNotSaved";
        }
        if (value.some((attendee) => !attendee.isValid)) {
          return "someAttendeesAreNotValid";
        }
      } finally {
        console.groupEnd();
      }
    }).bind(this),
  });

  public datePickerLoading = new PendingStatus();
  public submittingStatus = new PendingStatus();
  @observable
  public selectedRegion: IRegionOption | undefined;
  @observable
  public selectedCity: ICityOption | undefined;
  @observable
  public selectedCityTouched: boolean = false;
  @observable
  public selectedInstructor: IInstructorOption | undefined;
  @observable
  public selectedOption: IInstructorSelectOption | undefined;
  @observable
  public selectedService: IServiceOption | undefined;
  @observable
  public selectedMotorcycleLessonType: MotorcycleLessonType | undefined;
  @observable
  public selectedDate: Date | undefined;
  @observable
  public datePickerDate: Date = new Date();
  @observable
  public selectedTime: Date | undefined;
  @observable
  public originalInstructor: IInstructorModel | undefined;
  private readonly cityOptionAssignResetDisposer!: IReactionDisposer;
  private readonly selectableDatesDisposer!: IReactionDisposer;
  private readonly selectableTimesDisposer!: IReactionDisposer;
  @observable
  private service!: IAcademyServiceModel;

  private availableInstructorsSlots = observable.array<IIntervalWithMeta>();

  @observable
  public selectedWheeledVehicle: VehicleType | undefined = undefined;

  @observable
  private clientsCurrentUnitId: number | null = null;
  @observable
  private clientsCurrentEventId: number | null = null;

  private initData = flow(function* (this: ScheduleModel, user: IUserModel) {
    const { profile, saldoBalance } = user;
    if (!profile || saldoBalance === 0) {
      // FEXME ! || !profile.currentLocationId
      throw new Error("No lessons left");
    }
    const data: IBookingConfig | undefined =
      yield this.coreApiService.getCurrentBookingConfig(user.id);

    if (!data) {
      throw new Error("Current package not found");
    }

    const { instructorId } = data;

    this.clientsCurrentUnitId = instructorId;
    this.restoreWheeledVehicle();

    this.restoreRegion(user);
    this.restoreService();
    this.restoreLocation(user);

    this.restoreInstructor(user);

    //this.selectGearType(profile.isAutomaticCar);

    const attendee = this.createAttendee({
      name: [user.firstName, user.lastName].join(" "),
      saved: true,
      ssn: user.ssn,
    });
    this.attendees.addAttendee(attendee);
  }).bind(this);

  private readonly validateAttendeesCount = flow(function* (
    this: ScheduleModel
  ) {
    yield when(() => !this.updatingSlotsLeftInClassStatus.isPending);
    try {
      console.group("validateAttendeesCount");
      const diff = this.slotsLeftInClass - this.attendees.value.length;
      if (diff < 0) {
        const restAttendees = this.attendees.value.slice(diff);
        restAttendees.forEach((attendee) => {
          attendee.addError(ERROR_CODE.ThereAreNoEnoughSlots);
        });
      }
    } finally {
      console.groupEnd();
    }
  }).bind(this);

  constructor(
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(HistorySymbol) private readonly history: History,
    @inject(I18nServiceSymbol) private readonly i18n: II18nService,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {
    // Automatically select first time occurrence
    this.selectableTimesDisposer = reaction(
      () => ({
        timeOptions: this.timeOptions,
      }),
      ({ timeOptions }) => {
        const selectableTimeOptions = timeOptions.filter((to) => !to.disabled);

        if (selectableTimeOptions.length === 1) {
          const {
            date,
            entityId,
            isClass,
            endTime,
            availableQty,
            seatsInSlot,
          } = selectableTimeOptions[0];

          this.setSlotsLeftInClass(availableQty);
          if (!isClass) {
            const instructor = this.getInstructorOptionById(entityId);
            this.selectInstructor(instructor);
            this.setSeatsInSlot(seatsInSlot);
            this.setSeatsLeftInLesson(availableQty);
          }

          this.selectTime(date);
          this.setLessonEndTime(endTime);
        }

        if (selectableTimeOptions.length === 0) {
          this.selectTime(undefined);
          this.setLessonEndTime(undefined);
        }
      }
    );
    /**
     * Reset selected city when no available option
     */
    this.cityOptionAssignResetDisposer = reaction(
      () => ({
        cityOptions: this.cityOptions,
      }),
      ({ cityOptions }) => {
        const selectedCity = this.selectedCity;
        if (
          selectedCity &&
          !cityOptions
            .map((cityOption) => cityOption.value)
            .some((cityValue) => cityValue === selectedCity.value)
        ) {
          this.selectCity(undefined);
          this.selectCityTouched(true);
        }
        if (cityOptions.length === 1) {
          this.selectCity(cityOptions[0]);
          this.selectCityTouched(true);
        }
      }
    );
    let selectableDatesPendingAction: Promise<any>;
    this.selectableDatesDisposer = reaction(
      () => ({
        datePickerDate: this.datePickerDate,
        selectedInstructor: this.selectedOption,
        selectedLocation: this.selectedCity,
        selectedService: this.serviceWithExchange,
      }),
      ({
        datePickerDate,
        selectedInstructor,
        selectedLocation,
        selectedService,
      }) => {
        selectableDatesPendingAction = this.updateSelectableDates(
          datePickerDate,
          !!this.instructorIdsList.length
            ? this.instructorIdsList
            : selectedInstructor && [Number(selectedInstructor.value)],
          selectedLocation?.value,
          selectedService
        ).catch((err) => console.error(err));
      },
      {
        scheduler: (run) => {
          if (selectableDatesPendingAction) {
            return selectableDatesPendingAction
              .catch((e) => console.log(e))
              .then(run);
          }
          run();
        },
      }
    );
  }
  public async mount() {
    const { user } = this.userStore;
    if (!user) {
      throw new Error("User must be authenticated");
    }
    try {
      await this.initData(user);
    } catch (e) {
      console.error("Initiating schedule model have failed.");
      console.error(e);
      throw new Error(this.i18n.i18next.t("schedule.contactUs").toString());
    }
  }
  public unmount() {
    this.cityOptionAssignResetDisposer();
    this.selectableTimesDisposer();
    this.selectableDatesDisposer();
  }

  @action
  public selectDate(date: Date | undefined) {
    this.selectedDate = date;
  }
  @action
  public changeDatePickerDate(date: Date) {
    this.datePickerDate = date;
  }

  @action
  public selectTime(time: Date | undefined) {
    this.selectedTime = time;
  }

  @action
  public setLessonEndTime(time: Date | undefined) {
    if (time) {
      this.lessonEndTime = time;
    }
  }

  @action
  public selectCityTouched(cityTouched: boolean) {
    this.selectedCityTouched = cityTouched;
  }

  public async book(): Promise<void> {
    if (this.submittingStatus.isPending) {
      console.warn("booking already in process");
      return;
    }
    try {
      this.submittingStatus.startPending();
      this.errorMsg.clear();
      const user = this.userStore.user!;

      const {
        selectedDate,
        selectedTime,
        selectedInstructor,
        selectedService,
        selectedCity,
        selectedMotorcycleLessonType,
      } = this;

      if (!selectedDate || !selectedTime) {
        throw new Error("Time is not selected");
      }
      if (!selectedService) {
        throw new Error("Service is not selected");
      }
      if (!selectedCity) {
        throw new Error("Location is not selected");
      }

      const attList: IApiSaldoAttendee[] | undefined = this.attendeesMode
        ? this.attendees.value.map((attendee) => {
            return {
              name: attendee.name.value,
              ssn: attendee.ssn.value,
            };
          })
        : undefined;
      const isNewbie = this.userStore.user?.isNewbie;

      await this.coreApiService.book(
        user.id,
        selectedService!.value,
        selectedDate,
        selectedTime,
        selectedCity.value,
        this.currentVehicleGearType,
        selectedInstructor?.model.id,
        attList,
        this.selectedWheeledVehicle === VehicleType.mc && isNewbie
          ? MotorcycleLessonType.Lesson
          : this.selectedMotorcycleLessonType
      );

      await this.userStore.user!.refreshSaldoCounts();

      if (user.saldoBalance > 1) {
        this.history.push("/booking/scheduleConfirm");
      } else {
        this.history.push("/booking");
      }
    } catch (e) {
      console.error(e);
      let errorMsg = e;
      if (isAxiosError(e)) {
        // DELETEME after migration
        const errorResponse = e.response;
        if (errorResponse && errorResponse.data.data) {
          errorMsg = errorResponse.data.data;
        }
      } else if (isCoreApiError(e)) {
        errorMsg = e.error;
      }

      this.errorMsg.setMessage(
        (errorMsg instanceof Error && errorMsg.message) || String(errorMsg)
      );
    } finally {
      this.submittingStatus.stopPending();
    }
  }

  @action
  public setWheeledVehicle(val: VehicleType, setTouched = false) {
    this.selectedWheeledVehicle = val;
  }

  @action
  public selectRegion(region: IRegionOption | undefined) {
    this.selectedRegion = region;
  }

  @action
  public selectCity(city: ICityOption | undefined) {
    this.selectedCity = city;
  }
  /* @action
  public selectGearType(isAutomaticCar?: boolean) {
    this.gearType = isAutomaticCar ? GearType.Automatic : GearType.Manual;
  }*/
  @action
  public selectMotorcycleLessonType(val: MotorcycleLessonType | undefined) {
    this.selectedMotorcycleLessonType = val;
  }

  @action
  public selectInstructor(instructorOption: IInstructorOption | undefined) {
    this.selectedInstructor = instructorOption;
  }

  @action
  public selectOption(instructor: IInstructorSelectOption | undefined) {
    if (!!instructor) {
      const instructorOption = this.instructorOptions.find(
        (x) => x.model.id === Number(instructor.value)
      );
      this.selectInstructor(instructorOption);
    }
    this.selectedOption = instructor;
  }
  @action
  public selectService(service: IServiceOption | undefined) {
    this.selectedService = service;
  }

  public getInstructorOptionById(id: number): IInstructorOption | undefined {
    const instructor = this.settingsStore.instructors.find(
      (currentInstructor) => id === currentInstructor.id
    );

    return !!instructor ? this.instructorToOption(instructor) : undefined;
  }
  @action
  public setSlotsLeftInClass(val: number) {
    this.slotsLeftInClass = val;
  }
  @action
  public setSeatsLeftInLesson(val: number) {
    this.seatsLeftInLesson = val;
  }
  @action
  public setSeatsInSlot(val: number) {
    this.seatsInSlot = val;
  }

  private createAttendee(attendeeRaw: IFormStateAttendee) {
    const attendee = new Attendee();
    attendee.name.setValue(attendeeRaw.name, true);
    attendee.ssn.setValue(attendeeRaw.ssn, true);
    if (attendeeRaw.saved) {
      attendee.save();
    }
    return attendee;
  }

  @action
  private replaceAvailableInstructorSlots(val: IIntervalWithMeta[]): void {
    this.availableInstructorsSlots.replace(val);
  }

  private async updateSelectableDates(
    datePickerDate: Date,
    selectedInstructorsIds: number[] | undefined,

    locationId?: number,
    selectedService?: IServiceOption
  ) {
    try {
      this.datePickerLoading.startPending();

      if (selectedService && locationId) {
        const from = moment(datePickerDate)
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const to = moment(datePickerDate)
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD");

        const isClass = !!selectedService && selectedService.isClass;
        const isNewbie = this.userStore.user?.isNewbie;
        const clientId = this.userStore.user?.id;
        const isFulfilled = <T>(
          p: PromiseSettledResult<T>
        ): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

        if (isClass) {
          const slots = await this.coreApiService.getAvailableClassesSlots(
            from,
            to,
            selectedService.value
          );

          console.debug(
            "Free slots in selected intro",
            this.freeSlotsInSelectedIntro
          );
          //
          const mappedSlots = slots.map((slot) => {
            return {
              availableQty: slot.qty,
              dates: { ...slot.interval },
              entityId: selectedService.value,
              isClass,
              seatsInSlot: slot.seats,
            };
          });

          this.replaceAvailableInstructorSlots(mappedSlots);
        } else {
          if (!!selectedInstructorsIds?.length) {
            const result = await Promise.allSettled(
              selectedInstructorsIds.map(async (instructorId) => {
                try {
                  const hasMultipleGearType =
                    this.currentVehicleGearType &&
                    [GearType.McA2, GearType.McA].includes(
                      this.currentVehicleGearType
                    ) &&
                    selectedService.model.meta?.key !== ServiceKey.DrivingTest;
                  const [slots, workload] = await Promise.all([
                    this.coreApiService.getAvailableSlots(
                      from,
                      to,
                      instructorId,
                      selectedService.value,
                      isClass,
                      hasMultipleGearType
                        ? MultipleGearType.McAMcA2
                        : this.currentVehicleGearType,
                      locationId,
                      this.selectedWheeledVehicle === VehicleType.mc && isNewbie
                        ? MotorcycleLessonType.Lesson
                        : this.selectedMotorcycleLessonType,
                      this.selectedWheeledVehicle === VehicleType.mc && isNewbie
                        ? 1
                        : undefined,
                      this.selectedWheeledVehicle === VehicleType.mc ||
                        (this.isMCPreSelected && !this.isCarPreSelected)
                        ? clientId
                        : undefined
                    ),
                    await this.coreApiService.getInstructorWorkload(
                      from,
                      to,
                      instructorId
                    ),
                  ]);
                  return slots.map((slot) => ({
                    availableQty: slot.qty,
                    dates: { ...slot.interval },
                    entityId: instructorId,
                    isClass: false,
                    seatsInSlot: slot.seats,
                    workload,
                  }));
                } catch (e) {
                  return [];
                }
              })
            );

            const dataDict: {
              [key: string]: IIntervalWithWorkload;
            } = {};

            const fulfilledValues = result
              .filter(isFulfilled)
              .map((p) => p.value);

            fulfilledValues
              .reduce((acc, val) => acc.concat(val), [])
              .forEach((el) => {
                const { dates } = el;
                const key = dates.from.getTime();

                if (dataDict.hasOwnProperty(key)) {
                  if (dataDict[key].workload >= el.workload) {
                    dataDict[key] = el;
                  }
                } else {
                  dataDict[key] = el;
                }
              });

            const availableTimeSlots: IIntervalWithMeta[] = Object.values(
              dataDict
            ).map((el) => {
              const { workload, ...res } = el;
              return res;
            });

            this.replaceAvailableInstructorSlots(availableTimeSlots);
          } else {
            this.replaceAvailableInstructorSlots([]);
          }
        }
      } else {
        console.debug(
          "No service or location selected loc: %s, service: %s",
          locationId,
          selectedService?.value
        );
        this.replaceAvailableInstructorSlots([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.datePickerLoading.stopPending();
    }
  }

  @action
  private setService(val: IAcademyServiceModel) {
    this.service = val;
  }

  @action
  private setOriginalInstructor(
    originalInstructor: IInstructorModel | undefined
  ) {
    this.originalInstructor = originalInstructor;
  }

  private instructorToOption(instructor: IInstructorModel): IInstructorOption {
    return {
      label: instructor.name[this.i18n.currentLanguage],
      model: instructor,
      value: String(instructor.id),
    };
  }

  private restoreWheeledVehicle() {
    const wheeledVehicles = this.services.map((ser) => ser.service.vehicleType);
    const wheeledVehicleTypes = new Set(wheeledVehicles);

    if (wheeledVehicleTypes.size >= 2) {
      this.selectedWheeledVehicle = undefined;
    } else
      wheeledVehicleTypes.has(VehicleType.mc)
        ? (this.selectedWheeledVehicle = VehicleType.mc)
        : (this.selectedWheeledVehicle = VehicleType.car);
  }

  private restoreRegion(user: IUserModel) {
    const { profile, currentRegionId } = user;
    let currentRegionOption: IRegionOption | undefined;

    if (profile.currentLocationId) {
      currentRegionOption = this.regionOptions.find(
        (regionOption: IRegionOption) => regionOption.value === currentRegionId
      );
    }

    const onlyOnePossibleRegion =
      this.regionOptions.length === 1 ? this.regionOptions[0] : undefined;
    this.selectRegion(currentRegionOption || onlyOnePossibleRegion);
  }

  private restoreLocation(user: IUserModel) {
    const { profile } = user;
    const currentLocationId = profile.currentLocationId;
    let currentLocationOption: ICityOption | undefined;

    if (currentLocationId) {
      currentLocationOption = this.allCityOptions.find(
        (locationOption: ICityOption) =>
          locationOption.value === currentLocationId
      );
    }

    const onlyOnePossibleLocation =
      this.allCityOptions.length === 1 ? this.allCityOptions[0] : undefined;
    this.selectCity(onlyOnePossibleLocation);
  }

  private restoreService() {
    if (this.serviceOptions.length === 1) {
      this.selectService(this.serviceOptions[0]);
    }
  }

  private restoreInstructor(user: IUserModel) {
    const { profile } = user;
    const currentInstructorId =
      profile.currentInstructorId || this.clientsCurrentUnitId;

    if (currentInstructorId) {
      const selectedInstructor = this.instructorOptions.find(
        (x) => x.model.id === currentInstructorId
      );
      this.selectOption(selectedInstructor);
    }
  }
}
